<template>
  <div class="container">
    <b-field grouped group-multiline>
      <b-select v-model="defaultSortDirection">
        <option value="asc">Default sort direction: ASC</option>
        <option value="desc">Default sort direction: DESC</option>
      </b-select>
      <b-select v-model="perPage" :disabled="!isPaginated">
        <option value="5">5 per page</option>
        <option value="10">10 per page</option>
        <option value="15">15 per page</option>
        <option value="20">20 per page</option>
      </b-select>
      <div class="control">
        <button
          class="button"
          @click="currentPage = 2"
          :disabled="!isPaginated"
        >
          Set page to 2
        </button>
      </div>
      <div class="control is-flex">
        <b-switch v-model="isPaginated">Paginated</b-switch>
      </div>
      <div class="control is-flex">
        <b-switch v-model="isPaginationSimple" :disabled="!isPaginated"
          >Simple pagination</b-switch
        >
      </div>
      <b-select v-model="paginationPosition" :disabled="!isPaginated">
        <option value="bottom">bottom pagination</option>
        <option value="top">top pagination</option>
        <option value="both">both</option>
      </b-select>
    </b-field>
    <b-table
      :data="data"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      :default-sort-direction="defaultSortDirection"
      detailed
      detail-key="_id"
      @details-open="row => $buefy.toast.open(`Expanded ${row.name}`)"
      :show-detail-icon="showDetailIcon"
      :opened-detailed="defaultOpenedDetails"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="_id"
        label="ID"
        :searchable="true"
        sortable
        numeric
        v-slot="props"
      >
        APPBI{{ props.row.kecamatan }}{{ props.row.status
        }}{{ props.row.nomor }}
      </b-table-column>
      <b-table-column
        field="name"
        label="Nama"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        field="tgllahir"
        label="Tgl. Lahir"
        sortable
        v-slot="props"
      >
        {{ new Date(props.row.tgllahir).toLocaleDateString() }}
      </b-table-column>
      <!-- <b-table-column field="agama" label="Agama" sortable v-slot="props">
        {{ props.row.agama }}
      </b-table-column> -->
      <b-table-column field="phone" label="Phone" sortable v-slot="props">
        {{ props.row.phone }}
      </b-table-column>
      <b-table-column
        field="email"
        label="Email"
        :searchable="true"
        sortable
        v-slot="props"
      >
        {{ props.row.email }}
      </b-table-column>
      <!-- <b-table-column field="asosiasi" label="Asosiasi" sortable v-slot="props">
        {{ props.row.asosiasi }}
      </b-table-column> -->
      <!-- <b-table-column field="created" label="Created" sortable v-slot="props">
        {{ props.row.created }}
      </b-table-column> -->
      <b-table-column field="created" label="Action" v-slot="props">
        <div class="buttons">
          <b-button
            type="is-primary"
            tag="router-link"
            :to="{ path: '/print/' + props.row._id }"
            >Print PDF</b-button
          >
          <b-button
            type="is-danger"
            @click="deleteUser(props.row._id)"
            icon-right="delete"
          />
          <b-button
            tag="router-link"
            :to="{ path: '/detail/' + props.row._id }"
            type="is-success"
            icon-right="pencil"
          />
        </div>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.perusahaan }}</strong>
                <br />
                {{ props.row.asosiasi }}
                <br />
                {{ props.row.agama }}
                <br />
                <small
                  >{{ props.row.tahunberdiri }} Pegawai
                  {{ props.row.jmlpegawai }}</small
                >
                <small>{{ props.row.alamatperusahaan }}</small>
                <br />
                {{ props.row.produksi }}
                <br />
                {{ props.row.created }}
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
    <div class="level pt-2">
      <div class="level-left"></div>
      <div class="level-right">
        <download-csv :data="toCSV">
          <b-button>Download All Data</b-button>
        </download-csv>
      </div>
    </div>
  </div>
</template>

<script>
import Provinsi from "../assets/provinsi.json";
import axios from "axios";
export default {
  name: "Anggota",
  data() {
    return {
      data: [],
      toCSV: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      currentPage: 1,
      perPage: 10,
      showDetailIcon: true,
      defaultOpenedDetails: []
    };
  },
  mounted() {
    axios.get("http://localhost:3000/user", this.anggota).then(res => {
      this.data = res.data;
    });
    axios.get("http://localhost:3000/user", this.anggota).then(res => {
      var respon = res.data;
      respon.forEach(function(a) {
        var item = Provinsi.find(x => x.id === a.provinsi.toString());
        a.provinsi = item["nama"];
        var link =
          "../assets/kabupaten/" + a.kabupaten.toString().slice(0, 2) + ".json";
        axios.get(link).then(
          response => {
            var item = response.data.find(x => x.id === a.kabupaten.toString());
            a.kabupaten = item["nama"];
          },
          function(error) {
            console.log(error);
          }
        );
        var linkkec =
          "../assets/kecamatan/" + a.kecamatan.toString().slice(0, 4) + ".json";
        return axios.get(linkkec).then(
          response => {
            var item = response.data.find(x => x.id === a.kecamatan.toString());
            a.kecamatan = item["nama"];
          },
          function(error) {
            console.log(error.statusText);
          }
        );
      });
      this.toCSV = respon;
    });
  },
  methods: {
    deleteUser(val) {
      this.$buefy.dialog.confirm({
        message: "Apakah yakin akan menghapus data ini?",
        onConfirm: () => {
          axios.delete("http://localhost:3000/user/" + val).then(res => {
            this.$buefy.toast.open({
              message: res.data.message,
              type: "is-success"
            });
            axios.get("http://localhost:3000/user", this.anggota).then(res => {
              this.data = res.data;
            });
          });
        }
      });
    },
    getKecamatan(event) {
      var num;
      if (event.target == "undefined") {
        num = event.target.value;
      } else {
        if (event.type == "change") {
          num = event.target.value;
        } else {
          num = event.toString().slice(0, 4);
        }
      }
      var link = "../assets/kecamatan/" + num + ".json";
      return axios.get(link).then(
        response => {
          return response.data;
        },
        function(error) {
          console.log(error.statusText);
        }
      );
    }
  }
};
</script>
