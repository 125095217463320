<template>
  <div>
    <vue-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="APBBI"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a7"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div>
          <div
            class="card"
            :style="{
              height: '250px',
              width: '400px',
              'background-image': 'url(' + anggota.preview + ')',
              'background-size': '400px'
            }"
          >
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <div style="padding-left: 5px; padding-top: 140px">
                    <p class="subtitle is-6">
                      <b>{{ anggota.name }}</b
                      ><br />
                      APPBI{{ anggota.kecamatan }}{{ anggota.status
                      }}{{ anggota.nomor }}
                    </p>
                  </div>
                  <div style="padding-left: 40px; padding-top: 15px">
                    <p class="subtitle is-6" style="font-size: 10px">
                      <b>{{
                        new Date(anggota.berlaku).toLocaleDateString()
                      }}</b>
                    </p>
                  </div>
                  <b-image
                    style="
                      position: absolute;
                      top: 85px;
                      left: 288px;
                      width: 95px;
                    "
                    :src="anggota.profile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <b-button class="mx-3 my-3" type="is-primary" @click="generateReport()"
      >Manual Print PDF</b-button
    >
  </div>
</template>

<script>
import Provinsi from "../assets/provinsi.json";
import VueHtml2pdf from "vue-html2pdf";
import axios from "axios";
export default {
  name: "Detail",
  components: {
    VueHtml2pdf
  },
  data() {
    return {
      dropFiles: null,
      anggota: null,
      images: [],
      Provinsi,
      Kabupaten: [],
      Kecamatan: []
    };
  },
  mounted() {
    axios
      .get("http://localhost:3000/user/" + this.$route.params.id)
      .then(res => {
        this.anggota = res.data;
        // this.getKabupaten(res.data.kabupaten);
        // this.getKecamatan(res.data.kecamatan);
        console.log("Masuk");
        this.activate();
      })
      .then(() => {
        console.log("Masuk 1");
      });
  },
  methods: {
    activate() {
      setTimeout(() => this.generateReport(), 1000);
    },
    generateReport() {
      console.log("Masuk p");
      this.$refs.html2Pdf.generatePdf();
    }
  }
};
</script>
